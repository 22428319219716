


export function getAnnotationsFromXml(path) {
    const xmlStr = '<?xml version=\'1.0\' encoding=\'UTF-8\'?>\n' +
        '<TEI xmlns=\'http://www.tei-c.org/ns/1.0\'>\n' +
        '\t<teiHeader>\n' +
        '\t\t<fileDesc>\n' +
        '\t\t\t<titleStmt>\n' +
        '\t\t\t\t<title type=\'main\'>Ep 14 - Oxen of the Sun_HTRplusTraining_duplicated</title>\n' +
        '\t\t\t</titleStmt>\n' +
        '\t\t\t<publicationStmt>\n' +
        '\t\t\t\t<publisher>tranScriptorium</publisher>\n' +
        '\t\t\t</publicationStmt>\n' +
        '\t\t\t<sourceDesc>\n' +
        '\t\t\t\t<bibl><publisher>TRP document creator: josip.batin@gmail.com</publisher></bibl>\n' +
        '\t\t\t</sourceDesc>\n' +
        '\t\t</fileDesc>\n' +
        '\t</teiHeader>\n' +
        '\t<facsimile xml:id=\'facs_5\'>\n' +
        '\t\t<surface ulx=\'0\' uly=\'0\' lrx=\'5990\' lry=\'3786\'>\n' +
        '\t\t\t<graphic url=\'05.tif\' width=\'5990px\' height=\'3786px\'/>\n' +
        '\t\t\t<zone points=\'541,2533 877,2533 877,2861 541,2861\' rendition=\'TextRegion\' subtype=\'page-number\' xml:id=\'facs_5_region_1612990312361_1667\'>\n' +
        '\t\t\t\t<zone points=\'563,2554 799,2538 855,2778 565,2783\' rendition=\'Line\' xml:id=\'facs_5_line_1612990321802_1670\'/>\n' +
        '\t\t\t</zone>\n' +
        '\t\t\t<zone points=\'990,268 2962,268 2962,3528 1315,3528 1146,2395 1358,2296 1364,2080\' rendition=\'TextRegion\' subtype=\'paragraph\' xml:id=\'facs_5_TextRegion_1612990185252_1656\'>\n' +
        '\t\t\t\t<zone points=\'1319,2122 1353,2113 1353,2168 1329,2174\' rendition=\'Line\' xml:id=\'facs_5_line_1612990185387_1659\'/>\n' +
        '\t\t\t\t<zone points=\'2792,274 2710,274 2629,274 2548,276 2466,276 2385,276 2304,278 2222,278 2141,278 2060,278 1979,280 1897,280 1816,280 1735,280 1653,280 1572,280 1491,280 1409,280 1328,280 1247,280 1166,278 1166,333 1247,335 1328,335 1409,335 1491,335 1572,335 1653,335 1735,335 1816,335 1897,335 1979,335 2060,333 2141,333 2222,333 2304,333 2385,331 2466,331 2548,331 2629,329 2710,329 2792,329\' rendition=\'Line\' xml:id=\'facs_5_r1l1\'/>\n' +
        '\t\t\t\t<zone points=\'2932,336 2839,330 2747,326 2655,322 2563,320 2471,318 2379,316 2287,316 2195,316 2103,316 2011,316 1918,318 1826,318 1734,319 1642,320 1550,320 1458,322 1366,322 1274,322 1182,322 1090,320 1090,419 1182,421 1274,421 1366,421 1458,421 1550,419 1642,419 1734,418 1826,417 1918,417 2011,415 2103,415 2195,415 2287,415 2379,415 2471,417 2563,419 2655,421 2747,425 2839,429 2932,435\' rendition=\'Line\' xml:id=\'facs_5_r1l2\'/>\n' +
        '\t\t\t\t<zone points=\'2948,436 2855,424 2762,416 2669,409 2576,404 2484,398 2391,396 2298,393 2205,392 2112,392 2020,392 1927,394 1834,394 1741,396 1648,398 1556,400 1463,402 1370,404 1277,404 1184,406 1092,406 1092,494 1184,494 1277,492 1370,492 1463,490 1556,488 1648,486 1741,484 1834,482 1927,482 2020,480 2112,480 2205,480 2298,481 2391,484 2484,486 2576,492 2669,497 2762,504 2855,512 2948,524\' rendition=\'Line\' xml:id=\'facs_5_r1l3\'/>\n' +
        '\t\t\t\t<zone points=\'2820,525 2735,520 2650,517 2565,513 2480,511 2396,508 2311,507 2226,505 2141,503 2056,503 1972,503 1887,503 1802,503 1717,503 1632,505 1548,506 1463,509 1378,511 1293,514 1208,517 1124,521 1124,585 1208,581 1293,578 1378,575 1463,573 1548,570 1632,569 1717,567 1802,567 1887,567 1972,567 2056,567 2141,567 2226,569 2311,571 2396,572 2480,575 2565,577 2650,581 2735,584 2820,589\' rendition=\'Line\' xml:id=\'facs_5_r1l4\'/>\n' +
        '\t\t\t\t<zone points=\'2794,571 2710,569 2626,567 2542,564 2459,561 2375,559 2291,555 2208,553 2124,551 2040,549 1957,547 1873,547 1789,547 1705,549 1622,551 1538,554 1454,557 1371,563 1287,570 1203,579 1120,589 1120,673 1203,663 1287,654 1371,647 1454,641 1538,638 1622,635 1705,633 1789,631 1873,631 1957,631 2040,633 2124,635 2208,637 2291,639 2375,643 2459,645 2542,648 2626,651 2710,653 2794,655\' rendition=\'Line\' xml:id=\'facs_5_r1l5\'/>\n' +
        '\t\t\t\t<zone points=\'2816,622 2732,630 2648,636 2564,639 2480,642 2396,642 2312,642 2228,642 2144,641 2060,640 1976,638 1892,638 1808,636 1724,636 1640,638 1556,639 1472,642 1388,647 1304,654 1220,665 1136,674 1136,742 1220,733 1304,722 1388,715 1472,710 1556,707 1640,706 1724,704 1808,704 1892,706 1976,706 2060,708 2144,709 2228,710 2312,710 2396,710 2480,710 2564,707 2648,704 2732,698 2816,690\' rendition=\'Line\' xml:id=\'facs_5_r1l6\'/>\n' +
        '\t\t\t\t<zone points=\'2928,724 2839,726 2750,726 2661,726 2573,726 2484,726 2395,726 2307,724 2218,724 2129,724 2041,724 1952,724 1863,724 1774,724 1686,725 1597,726 1508,728 1420,730 1331,734 1242,738 1154,742 1154,805 1242,801 1331,797 1420,793 1508,791 1597,789 1686,788 1774,787 1863,787 1952,787 2041,787 2129,787 2218,787 2307,787 2395,789 2484,789 2573,789 2661,789 2750,789 2839,789 2928,787\' rendition=\'Line\' xml:id=\'facs_5_r1l7\'/>\n' +
        '\t\t\t\t<zone points=\'2914,803 2825,799 2736,796 2647,793 2558,791 2469,789 2380,789 2291,787 2202,787 2113,787 2024,789 1935,789 1846,791 1757,793 1668,793 1579,795 1490,797 1401,799 1312,801 1223,803 1134,805 1134,878 1223,876 1312,874 1401,872 1490,870 1579,868 1668,866 1757,866 1846,864 1935,862 2024,862 2113,860 2202,860 2291,860 2380,862 2469,862 2558,864 2647,866 2736,869 2825,872 2914,876\' rendition=\'Line\' xml:id=\'facs_5_r1l8\'/>\n' +
        '\t\t\t\t<zone points=\'2758,842 2678,842 2598,842 2519,844 2439,844 2360,844 2280,846 2200,846 2121,848 2041,849 1962,850 1882,852 1802,854 1723,856 1643,858 1564,862 1484,864 1404,868 1325,871 1245,874 1166,878 1166,952 1245,948 1325,945 1404,942 1484,938 1564,936 1643,932 1723,930 1802,928 1882,926 1962,924 2041,923 2121,922 2200,920 2280,920 2360,918 2439,918 2519,918 2598,916 2678,916 2758,916\' rendition=\'Line\' xml:id=\'facs_5_r1l9\'/>\n' +
        '\t\t\t\t<zone points=\'2898,889 2350,921 2289,921 2228,921 2167,921 2106,921 2044,921 1983,923 1922,925 1861,925 1800,928 1738,931 1677,932 1616,935 1555,937 1494,939 1432,942 1371,945 1310,949 1249,951 1188,953 1188,1016 1249,1014 1310,1012 1371,1008 1432,1005 1494,1002 1555,1000 1616,998 1677,995 1738,994 1800,991 1861,988 1922,988 1983,986 2044,984 2106,984 2167,984 2228,984 2289,984 2350,984 2898,952\' rendition=\'Line\' xml:id=\'facs_5_r1l10\'/>\n' +
        '\t\t\t\t<zone points=\'2794,964 2767,964 2740,965 2713,968 2686,964 2716,963 2643,960 2598,960 2520,973 2442,960 2364,969 2284,966 2205,972 2127,978 2049,981 1971,985 1892,987 1814,989 1736,991 1657,992 1579,995 1501,997 1422,1000 1344,1004 1266,1010 1188,1017 1188,1083 1266,1076 1344,1070 1422,1066 1501,1063 1579,1061 1657,1058 1736,1057 1814,1055 1892,1053 1971,1051 2049,1047 2127,1044 2205,1038 2284,1032 2364,1035 2442,1026 2520,1039 2598,1026 2643,1026 2716,1029 2686,1030 2713,1034 2740,1031 2767,1030 2794,1030\' rendition=\'Line\' xml:id=\'facs_5_line_1583250546123_349\'/>\n' +
        '\t\t\t\t<zone points=\'2944,1036 2887,1035 2831,1034 2775,1034 2719,1034 2663,1034 2606,1034 2550,1036 2494,1036 2438,1038 2382,1040 2325,1041 2269,1042 2213,1043 2157,1044 2101,1044 2044,1044 1988,1044 1932,1044 1876,1042 1820,1040 1754,1072 1727,1072 1700,1072 1673,1074 1647,1074 1620,1074 1593,1076 1567,1076 1540,1077 1513,1078 1487,1080 1460,1080 1433,1082 1406,1082 1380,1084 1353,1084 1326,1084 1300,1084 1273,1084 1246,1082 1220,1082 1220,1145 1246,1145 1273,1147 1300,1147 1326,1147 1353,1147 1380,1147 1406,1145 1433,1145 1460,1143 1487,1143 1513,1141 1540,1140 1567,1139 1593,1139 1620,1137 1647,1137 1673,1137 1700,1135 1727,1135 1754,1135 1820,1103 1876,1105 1932,1107 1988,1107 2044,1107 2101,1107 2157,1107 2213,1106 2269,1105 2325,1104 2382,1103 2438,1101 2494,1099 2550,1099 2606,1097 2663,1097 2719,1097 2775,1097 2831,1097 2887,1098 2944,1099\' rendition=\'Line\' xml:id=\'facs_5_line_1583250344537_282\'/>\n' +
        '\t\t\t\t<zone points=\'2854,1075 2773,1077 2692,1081 2611,1084 2530,1087 2449,1089 2368,1091 2287,1093 2206,1095 2125,1097 2045,1099 1964,1102 1883,1106 1802,1108 1721,1112 1640,1116 1559,1120 1478,1125 1397,1132 1316,1138 1236,1147 1236,1212 1316,1203 1397,1197 1478,1190 1559,1185 1640,1181 1721,1177 1802,1173 1883,1171 1964,1167 2045,1164 2125,1162 2206,1160 2287,1158 2368,1156 2449,1154 2530,1152 2611,1149 2692,1146 2773,1142 2854,1140\' rendition=\'Line\' xml:id=\'facs_5_r1l15\'/>\n' +
        '\t\t\t\t<zone points=\'2838,1147 2758,1149 2679,1152 2600,1154 2521,1157 2442,1160 2363,1162 2284,1165 2205,1169 2126,1172 2047,1175 1967,1179 1888,1181 1809,1185 1730,1188 1651,1192 1572,1196 1493,1199 1414,1203 1335,1209 1256,1213 1256,1273 1335,1269 1414,1263 1493,1259 1572,1256 1651,1252 1730,1248 1809,1245 1888,1241 1967,1239 2047,1235 2126,1232 2205,1229 2284,1225 2363,1222 2442,1220 2521,1217 2600,1214 2679,1212 2758,1209 2838,1207\' rendition=\'Line\' xml:id=\'facs_5_r1l16\'/>\n' +
        '\t\t\t\t<zone points=\'2940,1209 2856,1207 2772,1207 2688,1207 2604,1207 2520,1209 2436,1211 2352,1215 2268,1217 2184,1220 2100,1225 2016,1229 1932,1234 1848,1238 1764,1243 1680,1248 1596,1253 1512,1259 1428,1263 1344,1268 1260,1273 1260,1347 1344,1342 1428,1337 1512,1333 1596,1327 1680,1322 1764,1317 1848,1312 1932,1308 2016,1303 2100,1299 2184,1294 2268,1291 2352,1289 2436,1285 2520,1283 2604,1281 2688,1281 2772,1281 2856,1281 2940,1283\' rendition=\'Line\' xml:id=\'facs_5_r1l17\'/>\n' +
        '\t\t\t\t<zone points=\'2870,1262 2788,1262 2707,1264 2626,1265 2545,1266 2464,1268 2383,1271 2302,1274 2221,1277 2140,1280 2059,1285 1977,1290 1896,1294 1815,1299 1734,1304 1653,1311 1572,1318 1491,1325 1410,1332 1329,1340 1248,1348 1248,1420 1329,1412 1410,1404 1491,1397 1572,1390 1653,1383 1734,1376 1815,1371 1896,1366 1977,1362 2059,1357 2140,1352 2221,1349 2302,1346 2383,1343 2464,1340 2545,1338 2626,1337 2707,1336 2788,1334 2870,1334\' rendition=\'Line\' xml:id=\'facs_5_r1l18\'/>\n' +
        '\t\t\t\t<zone points=\'2834,1345 2755,1347 2677,1349 2599,1350 2520,1353 2442,1356 2364,1360 2285,1365 2207,1369 2129,1373 2051,1377 1972,1383 1894,1388 1816,1392 1737,1397 1659,1402 1581,1407 1502,1411 1424,1415 1346,1418 1268,1421 1268,1474 1346,1471 1424,1468 1502,1464 1581,1460 1659,1455 1737,1450 1816,1445 1894,1441 1972,1436 2051,1430 2129,1426 2207,1422 2285,1418 2364,1413 2442,1409 2520,1406 2599,1403 2677,1402 2755,1400 2834,1398\' rendition=\'Line\' xml:id=\'facs_5_r1l19\'/>\n' +
        '\t\t\t\t<zone points=\'2870,1399 2791,1399 2712,1399 2633,1401 2554,1403 2475,1405 2396,1408 2317,1411 2238,1415 2159,1419 2081,1423 2002,1427 1923,1433 1844,1438 1765,1443 1686,1449 1607,1454 1528,1459 1449,1465 1370,1470 1292,1475 1292,1543 1370,1538 1449,1533 1528,1527 1607,1522 1686,1517 1765,1511 1844,1506 1923,1501 2002,1495 2081,1491 2159,1487 2238,1483 2317,1479 2396,1476 2475,1473 2554,1471 2633,1469 2712,1467 2791,1467 2870,1467\' rendition=\'Line\' xml:id=\'facs_5_r1l20\'/>\n' +
        '\t\t\t\t<zone points=\'2962,1463 2878,1463 2794,1465 2710,1465 2626,1467 2542,1469 2458,1471 2374,1472 2290,1475 2206,1477 2123,1479 2039,1483 1955,1487 1871,1491 1787,1496 1703,1501 1619,1509 1535,1516 1451,1524 1367,1532 1284,1543 1284,1608 1367,1597 1451,1589 1535,1581 1619,1574 1703,1566 1787,1561 1871,1556 1955,1552 2039,1548 2123,1544 2206,1542 2290,1540 2374,1537 2458,1536 2542,1534 2626,1532 2710,1530 2794,1530 2878,1528 2962,1528\' rendition=\'Line\' xml:id=\'facs_5_r1l21\'/>\n' +
        '\t\t\t\t<zone points=\'2894,1537 2814,1540 2734,1543 2654,1545 2574,1548 2494,1551 2414,1553 2334,1557 2254,1560 2174,1564 2094,1567 2014,1571 1934,1575 1854,1578 1774,1583 1694,1587 1614,1591 1534,1596 1454,1601 1374,1605 1294,1609 1294,1668 1374,1664 1454,1660 1534,1655 1614,1650 1694,1646 1774,1642 1854,1637 1934,1634 2014,1630 2094,1626 2174,1623 2254,1619 2334,1616 2414,1612 2494,1610 2574,1607 2654,1604 2734,1602 2814,1599 2894,1596\' rendition=\'Line\' xml:id=\'facs_5_r1l22\'/>\n' +
        '\t\t\t\t<zone points=\'2858,1581 2779,1585 2701,1590 2623,1595 2545,1599 2467,1603 2389,1607 2311,1612 2233,1615 2155,1619 2077,1624 1998,1627 1920,1631 1842,1635 1764,1641 1686,1645 1608,1649 1530,1654 1452,1659 1374,1664 1296,1669 1296,1738 1374,1733 1452,1728 1530,1723 1608,1718 1686,1714 1764,1710 1842,1704 1920,1700 1998,1696 2077,1693 2155,1688 2233,1684 2311,1681 2389,1676 2467,1672 2545,1668 2623,1664 2701,1659 2779,1654 2858,1650\' rendition=\'Line\' xml:id=\'facs_5_r1l23\'/>\n' +
        '\t\t\t\t<zone points=\'2874,1662 2795,1666 2716,1669 2637,1672 2559,1675 2480,1678 2401,1683 2323,1687 2244,1692 2165,1696 2087,1700 2008,1704 1929,1708 1850,1712 1772,1716 1693,1720 1614,1724 1536,1728 1457,1732 1378,1735 1300,1738 1300,1800 1378,1797 1457,1794 1536,1790 1614,1786 1693,1782 1772,1778 1850,1774 1929,1770 2008,1766 2087,1762 2165,1758 2244,1754 2323,1749 2401,1745 2480,1740 2559,1737 2637,1734 2716,1731 2795,1728 2874,1724\' rendition=\'Line\' xml:id=\'facs_5_r1l24\'/>\n' +
        '\t\t\t\t<zone points=\'2862,1707 2786,1714 2710,1720 2634,1727 2558,1731 2482,1737 2406,1743 2330,1746 2254,1751 2178,1755 2103,1759 2027,1763 1951,1766 1875,1771 1799,1774 1723,1778 1647,1783 1571,1787 1495,1791 1419,1796 1344,1801 1344,1866 1419,1861 1495,1856 1571,1852 1647,1848 1723,1843 1799,1839 1875,1836 1951,1831 2027,1828 2103,1824 2178,1820 2254,1816 2330,1811 2406,1808 2482,1802 2558,1796 2634,1792 2710,1785 2786,1779 2862,1772\' rendition=\'Line\' xml:id=\'facs_5_r1l25\'/>\n' +
        '\t\t\t\t<zone points=\'2900,1788 2822,1788 2744,1790 2667,1792 2589,1795 2512,1798 2434,1802 2356,1805 2279,1811 2201,1815 2124,1820 2046,1827 1968,1832 1891,1838 1813,1842 1736,1848 1658,1852 1580,1856 1503,1860 1425,1864 1348,1866 1348,1929 1425,1927 1503,1923 1580,1919 1658,1915 1736,1911 1813,1905 1891,1901 1968,1895 2046,1890 2124,1883 2201,1878 2279,1874 2356,1868 2434,1865 2512,1861 2589,1858 2667,1855 2744,1853 2822,1851 2900,1851\' rendition=\'Line\' xml:id=\'facs_5_r1l26\'/>\n' +
        '\t\t\t\t<zone points=\'2566,1856 2505,1860 2445,1864 2385,1867 2325,1871 2265,1874 2205,1877 2145,1880 2085,1884 2025,1886 1965,1890 1904,1894 1844,1897 1784,1901 1724,1904 1664,1908 1604,1912 1544,1917 1484,1921 1424,1924 1364,1930 1364,1999 1424,1993 1484,1990 1544,1986 1604,1981 1664,1977 1724,1973 1784,1970 1844,1966 1904,1963 1965,1959 2025,1955 2085,1953 2145,1949 2205,1946 2265,1943 2325,1940 2385,1936 2445,1933 2505,1929 2566,1925\' rendition=\'Line\' xml:id=\'facs_5_r1l27\'/>\n' +
        '\t\t\t\t<zone points=\'2922,1922 2844,1924 2766,1926 2688,1928 2610,1932 2532,1935 2454,1938 2376,1942 2298,1945 2220,1950 2143,1955 2065,1959 1987,1964 1909,1968 1831,1973 1753,1978 1675,1982 1597,1986 1519,1991 1441,1996 1364,2000 1364,2066 1441,2062 1519,2057 1597,2052 1675,2048 1753,2044 1831,2039 1909,2034 1987,2030 2065,2025 2143,2021 2220,2016 2298,2011 2376,2008 2454,2004 2532,2001 2610,1998 2688,1994 2766,1992 2844,1990 2922,1988\' rendition=\'Line\' xml:id=\'facs_5_r1l28\'/>\n' +
        '\t\t\t\t<zone points=\'2836,1985 2763,1990 2690,1993 2617,1996 2544,2000 2472,2003 2399,2005 2326,2009 2253,2013 2180,2017 2108,2019 2035,2023 1962,2027 1889,2031 1816,2034 1744,2038 1671,2043 1598,2049 1525,2054 1452,2061 1380,2067 1380,2136 1452,2130 1525,2123 1598,2118 1671,2112 1744,2107 1816,2103 1889,2100 1962,2096 2035,2092 2108,2088 2180,2086 2253,2082 2326,2078 2399,2074 2472,2072 2544,2069 2617,2065 2690,2062 2763,2059 2836,2054\' rendition=\'Line\' xml:id=\'facs_5_r1l29\'/>\n' +
        '\t\t\t\t<zone points=\'2922,2065 2844,2067 2767,2069 2689,2071 2612,2075 2534,2077 2457,2081 2379,2085 2302,2089 2224,2093 2147,2096 2069,2101 1992,2104 1914,2109 1837,2113 1759,2117 1682,2122 1604,2125 1527,2129 1449,2133 1372,2137 1372,2199 1449,2195 1527,2191 1604,2187 1682,2184 1759,2179 1837,2175 1914,2171 1992,2166 2069,2163 2147,2158 2224,2155 2302,2151 2379,2147 2457,2143 2534,2139 2612,2137 2689,2133 2767,2131 2844,2129 2922,2127\' rendition=\'Line\' xml:id=\'facs_5_r1l30\'/>\n' +
        '\t\t\t\t<zone points=\'2922,2144 2845,2142 2768,2140 2691,2141 2614,2142 2537,2144 2460,2146 2383,2150 2306,2152 2229,2156 2153,2160 2076,2165 1999,2168 1922,2174 1845,2178 1768,2182 1691,2187 1614,2191 1537,2195 1460,2198 1384,2200 1384,2265 1460,2263 1537,2260 1614,2256 1691,2252 1768,2247 1845,2243 1922,2239 1999,2233 2076,2230 2153,2225 2229,2221 2306,2217 2383,2215 2460,2211 2537,2209 2614,2207 2691,2206 2768,2205 2845,2207 2922,2209\' rendition=\'Line\' xml:id=\'facs_5_r1l31\'/>\n' +
        '\t\t\t\t<zone points=\'2444,2258 2391,2260 2338,2262 2286,2264 2233,2266 2181,2269 2128,2273 2075,2276 2023,2280 1970,2283 1918,2288 1865,2292 1812,2296 1760,2299 1707,2303 1655,2306 1602,2309 1549,2313 1497,2316 1444,2319 1392,2322 1392,2346 1444,2343 1497,2340 1549,2337 1602,2333 1655,2330 1707,2327 1760,2323 1812,2320 1865,2316 1918,2312 1970,2307 2023,2304 2075,2300 2128,2297 2181,2293 2233,2290 2286,2288 2338,2286 2391,2284 2444,2282\' rendition=\'Line\' xml:id=\'facs_5_r1l33\'/>\n' +
        '\t\t\t\t<zone points=\'2898,2294 2846,2294 2794,2296 2742,2297 2690,2299 2639,2302 2587,2304 2535,2308 2483,2312 2431,2316 2380,2320 2328,2323 2276,2327 2224,2330 2172,2334 2121,2336 2069,2340 2017,2342 1965,2344 1913,2345 1862,2346 1862,2410 1913,2409 1965,2408 2017,2406 2069,2404 2121,2400 2172,2398 2224,2394 2276,2391 2328,2387 2380,2384 2431,2380 2483,2376 2535,2372 2587,2368 2639,2366 2690,2363 2742,2361 2794,2360 2846,2358 2898,2358\' rendition=\'Line\' xml:id=\'facs_5_r1l34\'/>\n' +
        '\t\t\t\t<zone points=\'2922,2329 2835,2329 2749,2329 2663,2329 2577,2332 2491,2335 2404,2338 2318,2343 2232,2348 2146,2353 2060,2359 1973,2365 1887,2371 1801,2377 1715,2383 1629,2388 1542,2394 1456,2399 1370,2403 1284,2407 1198,2411 1198,2521 1284,2517 1370,2513 1456,2509 1542,2504 1629,2498 1715,2493 1801,2487 1887,2481 1973,2475 2060,2469 2146,2463 2232,2458 2318,2453 2404,2448 2491,2445 2577,2442 2663,2439 2749,2439 2835,2439 2922,2439\' rendition=\'Line\' xml:id=\'facs_5_r1l35\'/>\n' +
        '\t\t\t\t<zone points=\'2816,2424 2740,2426 2665,2428 2589,2430 2514,2434 2439,2438 2363,2441 2288,2446 2212,2451 2137,2456 2062,2460 1986,2466 1911,2472 1835,2477 1760,2483 1685,2488 1609,2494 1534,2498 1458,2503 1383,2507 1308,2512 1272,2514 1240,2520 1208,2522 1208,2601 1240,2599 1272,2593 1308,2591 1383,2586 1458,2582 1534,2577 1609,2573 1685,2567 1760,2562 1835,2556 1911,2551 1986,2545 2062,2539 2137,2535 2212,2530 2288,2525 2363,2520 2439,2517 2514,2513 2589,2509 2665,2507 2740,2505 2816,2503\' rendition=\'Line\' xml:id=\'facs_5_line_1583409386531_4137\'/>\n' +
        '\t\t\t\t<zone points=\'2910,2507 2826,2509 2743,2513 2659,2515 2576,2519 2493,2523 2409,2527 2326,2533 2242,2537 2159,2543 2076,2548 1992,2552 1909,2559 1825,2564 1742,2569 1659,2576 1575,2581 1492,2586 1408,2591 1325,2596 1242,2601 1242,2677 1325,2672 1408,2667 1492,2662 1575,2657 1659,2652 1742,2645 1825,2640 1909,2635 1992,2628 2076,2624 2159,2619 2242,2613 2326,2609 2409,2603 2493,2599 2576,2595 2659,2591 2743,2589 2826,2585 2910,2583\' rendition=\'Line\' xml:id=\'facs_5_r1l38\'/>\n' +
        '\t\t\t\t<zone points=\'2902,2580 2820,2582 2738,2586 2657,2590 2575,2593 2494,2597 2412,2600 2330,2605 2249,2609 2167,2614 2086,2619 2004,2624 1922,2629 1841,2635 1759,2640 1678,2645 1596,2651 1514,2659 1433,2665 1351,2671 1270,2678 1270,2763 1351,2756 1433,2750 1514,2744 1596,2736 1678,2730 1759,2725 1841,2720 1922,2714 2004,2709 2086,2704 2167,2699 2249,2694 2330,2690 2412,2685 2494,2682 2575,2678 2657,2675 2738,2671 2820,2667 2902,2665\' rendition=\'Line\' xml:id=\'facs_5_r1l39\'/>\n' +
        '\t\t\t\t<zone points=\'2770,2673 2702,2677 2635,2683 2568,2686 2501,2691 2434,2693 2367,2697 2300,2701 2233,2703 2166,2707 2099,2711 2031,2713 1964,2717 1897,2721 1830,2723 1763,2727 1696,2730 1629,2735 1562,2738 1495,2743 1428,2749 1374,2763 1347,2759 1320,2763 1294,2763 1294,2836 1320,2836 1347,2832 1374,2836 1428,2822 1495,2816 1562,2811 1629,2808 1696,2803 1763,2800 1830,2796 1897,2794 1964,2790 2031,2786 2099,2784 2166,2780 2233,2776 2300,2774 2367,2770 2434,2766 2501,2764 2568,2759 2635,2756 2702,2750 2770,2746\' rendition=\'Line\' xml:id=\'facs_5_line_1583409384070_4133\'/>\n' +
        '\t\t\t\t<zone points=\'2922,2732 2838,2737 2755,2740 2672,2745 2588,2748 2505,2752 2422,2757 2338,2762 2255,2766 2172,2770 2089,2775 2005,2781 1922,2786 1839,2792 1755,2796 1672,2803 1589,2809 1505,2815 1422,2822 1339,2828 1256,2836 1256,2917 1339,2909 1422,2903 1505,2896 1589,2890 1672,2884 1755,2877 1839,2873 1922,2867 2005,2862 2089,2856 2172,2851 2255,2847 2338,2843 2422,2838 2505,2833 2588,2829 2672,2826 2755,2821 2838,2818 2922,2813\' rendition=\'Line\' xml:id=\'facs_5_r1l42\'/>\n' +
        '\t\t\t\t<zone points=\'2814,2804 2737,2809 2661,2813 2584,2818 2508,2822 2431,2828 2355,2832 2278,2836 2202,2840 2125,2844 2049,2850 1972,2855 1896,2861 1819,2866 1743,2871 1666,2878 1590,2884 1513,2892 1437,2901 1360,2909 1284,2918 1284,2997 1360,2988 1437,2980 1513,2971 1590,2963 1666,2957 1743,2950 1819,2945 1896,2940 1972,2934 2049,2929 2125,2923 2202,2919 2278,2915 2355,2911 2431,2907 2508,2901 2584,2897 2661,2892 2737,2888 2814,2883\' rendition=\'Line\' xml:id=\'facs_5_r1l43\'/>\n' +
        '\t\t\t\t<zone points=\'2930,2897 2848,2899 2767,2901 2686,2903 2605,2906 2524,2909 2443,2914 2362,2917 2281,2922 2200,2927 2119,2931 2037,2938 1956,2943 1875,2948 1794,2955 1713,2961 1632,2968 1551,2976 1470,2982 1389,2989 1308,2997 1308,3069 1389,3061 1470,3054 1551,3048 1632,3040 1713,3033 1794,3027 1875,3020 1956,3015 2037,3010 2119,3003 2200,2999 2281,2994 2362,2989 2443,2986 2524,2981 2605,2978 2686,2975 2767,2973 2848,2971 2930,2969\' rendition=\'Line\' xml:id=\'facs_5_r1l44\'/>\n' +
        '\t\t\t\t<zone points=\'2840,2964 2763,2968 2687,2972 2611,2976 2535,2980 2459,2986 2382,2991 2306,2996 2230,3000 2154,3007 2078,3012 2001,3018 1925,3024 1849,3029 1773,3036 1697,3042 1620,3048 1544,3054 1468,3058 1392,3064 1316,3070 1316,3148 1392,3142 1468,3136 1544,3132 1620,3126 1697,3120 1773,3114 1849,3107 1925,3102 2001,3096 2078,3090 2154,3085 2230,3078 2306,3074 2382,3069 2459,3064 2535,3058 2611,3054 2687,3050 2763,3046 2840,3042\' rendition=\'Line\' xml:id=\'facs_5_r1l45\'/>\n' +
        '\t\t\t\t<zone points=\'2890,3061 2811,3063 2733,3065 2655,3067 2577,3071 2499,3073 2421,3077 2343,3081 2265,3086 2187,3090 2109,3094 2030,3100 1952,3104 1874,3111 1796,3115 1718,3121 1640,3126 1562,3132 1484,3138 1406,3143 1328,3149 1328,3237 1406,3231 1484,3226 1562,3220 1640,3214 1718,3209 1796,3203 1874,3199 1952,3192 2030,3188 2109,3182 2187,3178 2265,3174 2343,3169 2421,3165 2499,3161 2577,3159 2655,3155 2733,3153 2811,3151 2890,3149\' rendition=\'Line\' xml:id=\'facs_5_r1l46\'/>\n' +
        '\t\t\t\t<zone points=\'2892,3132 2814,3132 2736,3134 2658,3135 2580,3138 2502,3140 2424,3144 2346,3148 2268,3152 2190,3158 2112,3163 2034,3168 1956,3175 1878,3183 1800,3188 1722,3195 1644,3204 1566,3212 1488,3221 1410,3230 1332,3238 1332,3332 1410,3324 1488,3315 1566,3306 1644,3298 1722,3289 1800,3282 1878,3277 1956,3269 2034,3262 2112,3257 2190,3252 2268,3246 2346,3242 2424,3238 2502,3234 2580,3232 2658,3229 2736,3228 2814,3226 2892,3226\' rendition=\'Line\' xml:id=\'facs_5_r1l47\'/>\n' +
        '\t\t\t\t<zone points=\'2940,3253 2862,3249 2784,3249 2706,3247 2628,3247 2551,3249 2473,3251 2395,3253 2317,3257 2239,3261 2162,3266 2084,3271 2006,3276 1928,3283 1850,3290 1773,3297 1695,3304 1617,3311 1539,3319 1461,3327 1384,3333 1384,3403 1461,3397 1539,3389 1617,3381 1695,3374 1773,3367 1850,3360 1928,3353 2006,3346 2084,3341 2162,3336 2239,3331 2317,3327 2395,3323 2473,3321 2551,3319 2628,3317 2706,3317 2784,3319 2862,3319 2940,3323\' rendition=\'Line\' xml:id=\'facs_5_r1l48\'/>\n' +
        '\t\t\t\t<zone points=\'2944,3358 2868,3354 2792,3352 2716,3350 2640,3350 2564,3352 2488,3352 2412,3356 2336,3358 2260,3361 2184,3366 2108,3371 2032,3375 1956,3379 1880,3384 1804,3387 1728,3392 1652,3396 1576,3400 1500,3402 1424,3404 1424,3467 1500,3465 1576,3463 1652,3459 1728,3455 1804,3450 1880,3447 1956,3442 2032,3438 2108,3434 2184,3429 2260,3424 2336,3421 2412,3419 2488,3415 2564,3415 2640,3413 2716,3413 2792,3415 2868,3417 2944,3421\' rendition=\'Line\' xml:id=\'facs_5_r1l49\'/>\n' +
        '\t\t\t\t<zone points=\'2824,3436 2752,3432 2681,3430 2610,3426 2539,3424 2468,3424 2397,3424 2326,3424 2255,3424 2184,3426 2113,3428 2041,3431 1970,3434 1899,3438 1828,3442 1757,3446 1686,3449 1615,3454 1544,3459 1473,3463 1402,3468 1402,3518 1473,3513 1544,3509 1615,3504 1686,3499 1757,3496 1828,3492 1899,3488 1970,3484 2041,3481 2113,3478 2184,3476 2255,3474 2326,3474 2397,3474 2468,3474 2539,3474 2610,3476 2681,3480 2752,3482 2824,3486\' rendition=\'Line\' xml:id=\'facs_5_r1l50\'/>\n' +
        '\t\t\t</zone>\n' +
        '\t\t\t<zone points=\'767,2232 1228,2097 1341,2107 1365,2168 1115,2286 1063,2298 768,2362\' rendition=\'TextRegion\' subtype=\'marginalia\' xml:id=\'facs_5_TextRegion_1612990185252_1655\'>\n' +
        '\t\t\t\t<zone points=\'776,2266 803,2263 830,2260 857,2256 885,2251 912,2246 939,2239 967,2230 994,2222 1021,2214 1049,2204 1076,2194 1103,2186 1130,2177 1158,2167 1185,2158 1212,2151 1240,2143 1267,2136 1294,2130 1319,2122 1329,2174 1294,2185 1267,2191 1240,2198 1212,2206 1185,2213 1158,2222 1130,2232 1103,2241 1076,2249 1049,2259 1021,2269 994,2277 967,2285 939,2294 912,2301 885,2306 857,2311 830,2315 803,2318 776,2321\' rendition=\'Line\' xml:id=\'facs_5_line_1612990185387_1658\'/>\n' +
        '\t\t\t</zone>\n' +
        '\t\t\t<zone points=\'3199,468 3204,944 3898,944 3893,468\' rendition=\'TextRegion\' subtype=\'marginalia\' xml:id=\'facs_5_r2\'>\n' +
        '\t\t\t\t<zone points=\'3509,482 3418,475 3418,543 3509,550\' rendition=\'Line\' xml:id=\'facs_5_line_1583250746255_407\'/>\n' +
        '\t\t\t\t<zone points=\'3898,521 3863,522 3828,525 3793,526 3759,529 3724,531 3689,534 3655,537 3620,540 3585,543 3551,545 3516,547 3481,549 3446,549 3412,550 3377,551 3342,548 3308,546 3273,544 3238,541 3204,535 3204,645 3238,651 3273,654 3308,656 3342,658 3377,661 3412,660 3446,659 3481,659 3516,657 3551,655 3585,653 3620,650 3655,647 3689,644 3724,641 3759,639 3793,636 3828,635 3863,632 3898,631\' rendition=\'Line\' xml:id=\'facs_5_r2l1\'/>\n' +
        '\t\t\t\t<zone points=\'3892,622 3857,625 3823,628 3789,630 3754,633 3720,636 3686,640 3651,644 3617,647 3583,650 3549,653 3514,656 3480,659 3446,660 3411,662 3377,662 3343,662 3308,660 3274,659 3240,655 3206,652 3206,731 3240,734 3274,738 3308,739 3343,741 3377,741 3411,741 3446,739 3480,738 3514,735 3549,732 3583,729 3617,726 3651,723 3686,719 3720,715 3754,712 3789,709 3823,707 3857,704 3892,701\' rendition=\'Line\' xml:id=\'facs_5_r2l2\'/>\n' +
        '\t\t\t\t<zone points=\'3838,696 3809,697 3780,700 3751,702 3723,705 3694,710 3665,712 3637,717 3608,721 3579,724 3551,728 3522,733 3493,735 3464,738 3436,740 3407,742 3378,742 3350,742 3321,740 3292,738 3264,734 3264,803 3292,807 3321,809 3350,811 3378,811 3407,811 3436,809 3464,807 3493,804 3522,802 3551,797 3579,793 3608,790 3637,786 3665,781 3694,779 3723,774 3751,771 3780,769 3809,766 3838,765\' rendition=\'Line\' xml:id=\'facs_5_r2l3\'/>\n' +
        '\t\t\t\t<zone points=\'3898,756 3867,757 3837,760 3806,763 3776,766 3745,769 3715,774 3684,776 3654,781 3623,785 3593,788 3562,793 3532,796 3501,800 3471,802 3440,805 3410,808 3379,809 3349,810 3318,812 3288,812 3288,879 3318,879 3349,877 3379,876 3410,875 3440,872 3471,869 3501,867 3532,863 3562,860 3593,855 3623,852 3654,848 3684,843 3715,841 3745,836 3776,833 3806,830 3837,827 3867,824 3898,823\' rendition=\'Line\' xml:id=\'facs_5_r2l4\'/>\n' +
        '\t\t\t\t<zone points=\'3886,839 3856,841 3827,843 3798,845 3768,847 3739,849 3710,852 3680,854 3651,855 3622,857 3593,859 3563,862 3534,863 3505,864 3475,867 3446,869 3417,871 3387,872 3358,874 3329,877 3300,879 3300,933 3329,931 3358,928 3387,926 3417,925 3446,923 3475,921 3505,918 3534,917 3563,916 3593,913 3622,911 3651,909 3680,908 3710,906 3739,903 3768,901 3798,899 3827,897 3856,895 3886,893\' rendition=\'Line\' xml:id=\'facs_5_r2l5\'/>\n' +
        '\t\t\t</zone>\n' +
        '\t\t\t<zone points=\'3405,2601 3711,2601 3711,2980 3405,2980\' rendition=\'TextRegion\' subtype=\'page-number\' xml:id=\'facs_5_region_1612990419591_1682\'>\n' +
        '\t\t\t\t<zone points=\'3436,2601 3651,2605 3601,2829 3434,2802\' rendition=\'Line\' subtype=\'page-number\' xml:id=\'facs_5_line_1612990448752_1695\'/>\n' +
        '\t\t\t</zone>\n' +
        '\t\t\t<zone points=\'3970,314 3970,3545 5772,3545 5772,314\' rendition=\'TextRegion\' subtype=\'paragraph\' xml:id=\'facs_5_r3\'>\n' +
        '\t\t\t\t<zone points=\'5696,321 5609,319 5523,319 5437,317 5350,317 5264,317 5178,315 5091,315 5005,315 4919,317 4833,317 4746,317 4660,317 4574,319 4487,319 4401,321 4315,323 4228,324 4142,325 4056,327 3970,329 3970,383 4056,381 4142,379 4228,378 4315,377 4401,375 4487,373 4574,373 4660,371 4746,371 4833,371 4919,371 5005,369 5091,369 5178,369 5264,371 5350,371 5437,371 5523,373 5609,373 5696,375\' rendition=\'Line\' xml:id=\'facs_5_r3l1\'/>\n' +
        '\t\t\t\t<zone points=\'5740,374 5651,372 5563,372 5474,372 5386,372 5297,372 5209,372 5120,372 5032,372 4943,372 4855,372 4766,374 4678,374 4589,375 4501,376 4412,378 4324,378 4235,380 4147,382 4058,382 3970,384 3970,454 4058,452 4147,452 4235,450 4324,448 4412,448 4501,446 4589,445 4678,444 4766,444 4855,442 4943,442 5032,442 5120,442 5209,442 5297,442 5386,442 5474,442 5563,442 5651,442 5740,444\' rendition=\'Line\' xml:id=\'facs_5_r3l2\'/>\n' +
        '\t\t\t\t<zone points=\'5696,388 5726,393 5718,442 5688,437\' rendition=\'Line\' xml:id=\'facs_5_line_1590388971545_219\'/>\n' +
        '\t\t\t\t<zone points=\'5748,447 5659,446 5571,445 5482,445 5394,445 5305,445 5217,445 5128,445 5040,445 4951,445 4863,447 4774,447 4686,447 4597,448 4509,449 4420,449 4332,451 4243,452 4155,453 4066,455 3978,455 3978,523 4066,523 4155,521 4243,520 4332,519 4420,517 4509,517 4597,516 4686,515 4774,515 4863,515 4951,513 5040,513 5128,513 5217,513 5305,513 5394,513 5482,513 5571,513 5659,514 5748,515\' rendition=\'Line\' xml:id=\'facs_5_r3l3\'/>\n' +
        '\t\t\t\t<zone points=\'5764,521 5676,516 5589,511 5501,509 5414,507 5326,505 5239,505 5151,505 5064,507 4976,509 4889,511 4801,513 4714,515 4626,517 4539,519 4451,521 4364,523 4276,523 4189,523 4101,523 4014,521 4014,593 4101,595 4189,595 4276,595 4364,595 4451,593 4539,591 4626,589 4714,587 4801,585 4889,583 4976,581 5064,579 5151,577 5239,577 5326,577 5414,579 5501,581 5589,583 5676,588 5764,593\' rendition=\'Line\' xml:id=\'facs_5_r3l4\'/>\n' +
        '\t\t\t\t<zone points=\'5772,596 5685,585 5598,576 5512,570 5425,568 5339,566 5252,566 5165,566 5079,568 4992,571 4906,574 4819,578 4732,582 4646,584 4559,588 4473,590 4386,592 4299,591 4213,590 4126,585 4040,580 4040,652 4126,657 4213,662 4299,663 4386,664 4473,662 4559,660 4646,656 4732,654 4819,650 4906,646 4992,643 5079,640 5165,638 5252,638 5339,638 5425,640 5512,642 5598,648 5685,657 5772,668\' rendition=\'Line\' xml:id=\'facs_5_r3l5\'/>\n' +
        '\t\t\t\t<zone points=\'5759,662 5656,653 5572,651 5488,649 5404,649 5321,649 5237,650 5153,651 5069,653 4985,656 4902,659 4818,661 4734,663 4650,665 4566,667 4483,669 4399,669 4315,669 4231,667 4147,665 4064,661 4064,725 4147,729 4231,731 4315,733 4399,733 4483,733 4566,731 4650,729 4734,727 4818,725 4902,723 4985,720 5069,717 5153,715 5237,714 5321,713 5404,713 5488,713 5572,715 5656,717 5759,726\' rendition=\'Line\' xml:id=\'facs_5_r3l6\'/>\n' +
        '\t\t\t\t<zone points=\'5752,713 5668,707 5585,703 5502,701 5418,699 5335,701 5252,703 5168,705 5085,707 5002,711 4919,716 4835,720 4752,723 4669,727 4585,729 4502,731 4419,733 4335,731 4252,731 4169,726 4086,721 4086,789 4169,794 4252,799 4335,799 4419,801 4502,799 4585,797 4669,795 4752,791 4835,788 4919,784 5002,779 5085,775 5168,773 5252,771 5335,769 5418,767 5502,769 5585,771 5668,775 5752,781\' rendition=\'Line\' xml:id=\'facs_5_r3l7\'/>\n' +
        '\t\t\t\t<zone points=\'5702,774 5583,770 5505,770 5427,770 5349,772 5271,773 5192,775 5114,778 5036,780 4958,783 4880,786 4801,789 4723,792 4645,794 4567,798 4489,799 4410,800 4332,802 4254,802 4176,802 4098,802 4098,867 4176,867 4254,867 4332,867 4410,865 4489,864 4567,863 4645,859 4723,857 4801,854 4880,851 4958,848 5036,845 5114,843 5192,840 5271,838 5349,837 5427,835 5505,835 5583,835 5702,839\' rendition=\'Line\' xml:id=\'facs_5_r3l8\'/>\n' +
        '\t\t\t\t<zone points=\'5755,854 5656,839 5574,835 5492,834 5410,833 5328,831 5246,833 5164,833 5082,835 5000,837 4919,839 4837,841 4755,844 4673,847 4591,851 4509,853 4427,857 4345,861 4263,863 4181,865 4100,867 4100,940 4181,938 4263,936 4345,934 4427,930 4509,926 4591,924 4673,920 4755,917 4837,914 4919,912 5000,910 5082,908 5164,906 5246,906 5328,904 5410,906 5492,907 5574,908 5656,912 5755,927\' rendition=\'Line\' xml:id=\'facs_5_r3l9\'/>\n' +
        '\t\t\t\t<zone points=\'5777,941 5649,910 5567,906 5485,902 5403,900 5321,898 5239,896 5157,897 5075,898 4993,899 4911,901 4828,904 4746,906 4664,908 4582,912 4500,915 4418,918 4336,920 4254,922 4172,924 4090,926 4090,997 4172,995 4254,993 4336,991 4418,989 4500,986 4582,983 4664,979 4746,977 4828,975 4911,972 4993,970 5075,969 5157,968 5239,967 5321,969 5403,971 5485,973 5567,977 5649,981 5777,1012\' rendition=\'Line\' xml:id=\'facs_5_r3l10\'/>\n' +
        '\t\t\t\t<zone points=\'5734,1005 5653,996 5573,991 5492,985 5412,983 5331,981 5251,979 5170,981 5090,982 5009,984 4929,987 4848,991 4768,993 4687,997 4607,1001 4526,1004 4446,1007 4365,1009 4285,1011 4204,1013 4124,1013 4124,1066 4204,1066 4285,1064 4365,1062 4446,1060 4526,1057 4607,1054 4687,1050 4768,1046 4848,1044 4929,1040 5009,1037 5090,1035 5170,1034 5251,1032 5331,1034 5412,1036 5492,1038 5573,1044 5653,1049 5734,1058\' rendition=\'Line\' xml:id=\'facs_5_r3l11\'/>\n' +
        '\t\t\t\t<zone points=\'5758,1066 5655,1044 5574,1038 5493,1034 5412,1032 5331,1032 5250,1032 5169,1034 5088,1036 5007,1038 4927,1042 4846,1046 4765,1050 4684,1053 4603,1056 4522,1060 4441,1062 4360,1064 4279,1064 4198,1064 4118,1062 4118,1124 4198,1126 4279,1126 4360,1126 4441,1124 4522,1122 4603,1118 4684,1115 4765,1112 4846,1108 4927,1104 5007,1100 5088,1098 5169,1096 5250,1094 5331,1094 5412,1094 5493,1096 5574,1100 5655,1106 5758,1128\' rendition=\'Line\' xml:id=\'facs_5_r3l12\'/>\n' +
        '\t\t\t\t<zone points=\'5717,1098 5599,1094 5521,1091 5442,1089 5364,1089 5285,1090 5207,1091 5128,1093 5050,1096 4971,1099 4893,1103 4814,1106 4736,1110 4657,1114 4579,1117 4500,1121 4422,1124 4343,1125 4265,1127 4186,1129 4108,1129 4108,1196 4186,1196 4265,1194 4343,1192 4422,1191 4500,1188 4579,1184 4657,1181 4736,1177 4814,1173 4893,1170 4971,1166 5050,1163 5128,1160 5207,1158 5285,1157 5364,1156 5442,1156 5521,1158 5599,1161 5717,1165\' rendition=\'Line\' xml:id=\'facs_5_r3l13\'/>\n' +
        '\t\t\t\t<zone points=\'5712,1164 5648,1165 5622,1165 5547,1161 5473,1159 5398,1157 5324,1155 5249,1155 5175,1157 5100,1159 5026,1160 4951,1163 4877,1166 4802,1169 4728,1173 4653,1177 4579,1181 4504,1184 4430,1187 4355,1190 4281,1193 4206,1195 4132,1197 4132,1264 4206,1262 4281,1260 4355,1257 4430,1254 4504,1251 4579,1248 4653,1244 4728,1240 4802,1236 4877,1233 4951,1230 5026,1227 5100,1226 5175,1224 5249,1222 5324,1222 5398,1224 5473,1226 5547,1228 5622,1232 5648,1232 5712,1231\' rendition=\'Line\' xml:id=\'facs_5_line_1583250363401_287\'/>\n' +
        '\t\t\t\t<zone points=\'5677,1208 5436,1212 5368,1214 5301,1217 5233,1220 5165,1223 5098,1225 5030,1228 4963,1231 4895,1234 4828,1237 4760,1240 4692,1243 4625,1246 4557,1249 4490,1251 4422,1255 4354,1257 4287,1261 4219,1262 4152,1265 4152,1330 4219,1327 4287,1326 4354,1322 4422,1320 4490,1316 4557,1314 4625,1311 4692,1308 4760,1305 4828,1302 4895,1299 4963,1296 5030,1293 5098,1290 5165,1288 5233,1285 5301,1282 5368,1279 5436,1277 5677,1273\' rendition=\'Line\' xml:id=\'facs_5_r3l16\'/>\n' +
        '\t\t\t\t<zone points=\'5756,1285 5651,1279 5573,1277 5495,1277 5416,1279 5338,1281 5260,1283 5181,1288 5103,1291 5025,1295 4947,1300 4868,1305 4790,1310 4712,1314 4633,1319 4555,1322 4477,1325 4398,1329 4320,1330 4242,1331 4164,1331 4164,1395 4242,1395 4320,1394 4398,1393 4477,1389 4555,1386 4633,1383 4712,1378 4790,1374 4868,1369 4947,1364 5025,1359 5103,1355 5181,1352 5260,1347 5338,1345 5416,1343 5495,1341 5573,1341 5651,1343 5756,1349\' rendition=\'Line\' xml:id=\'facs_5_r3l17\'/>\n' +
        '\t\t\t\t<zone points=\'5710,1335 5633,1335 5557,1335 5480,1335 5404,1337 5327,1339 5251,1341 5174,1345 5098,1349 5021,1352 4945,1357 4868,1361 4792,1365 4715,1370 4639,1373 4562,1379 4486,1383 4409,1385 4333,1389 4256,1391 4180,1395 4180,1464 4256,1460 4333,1458 4409,1454 4486,1452 4562,1448 4639,1442 4715,1439 4792,1434 4868,1430 4945,1426 5021,1421 5098,1418 5174,1414 5251,1410 5327,1408 5404,1406 5480,1404 5557,1404 5633,1404 5710,1404\' rendition=\'Line\' xml:id=\'facs_5_r3l18\'/>\n' +
        '\t\t\t\t<zone points=\'5743,1411 5653,1404 5593,1403 5533,1401 5473,1403 5413,1403 5353,1405 5293,1407 5233,1409 5173,1411 5113,1414 5052,1418 4992,1421 4932,1425 4872,1428 4812,1432 4752,1434 4692,1437 4632,1439 4572,1441 4512,1441 4444,1453 4416,1453 4389,1454 4362,1457 4334,1459 4307,1461 4280,1464 4252,1465 4225,1465 4198,1463 4198,1528 4225,1530 4252,1530 4280,1529 4307,1526 4334,1524 4362,1522 4389,1519 4416,1518 4444,1518 4512,1506 4572,1506 4632,1504 4692,1502 4752,1499 4812,1497 4872,1493 4932,1490 4992,1486 5052,1483 5113,1479 5173,1476 5233,1474 5293,1472 5353,1470 5413,1468 5473,1468 5533,1466 5593,1468 5653,1469 5743,1476\' rendition=\'Line\' xml:id=\'facs_5_line_1583250491208_338\'/>\n' +
        '\t\t\t\t<zone points=\'5698,1462 5634,1470 5614,1466 5543,1471 5473,1474 5402,1478 5332,1481 5261,1485 5191,1488 5120,1490 5050,1494 4979,1496 4909,1498 4838,1501 4768,1503 4697,1506 4627,1510 4556,1512 4486,1515 4415,1518 4345,1522 4274,1526 4204,1530 4204,1590 4274,1586 4345,1582 4415,1578 4486,1575 4556,1572 4627,1570 4697,1566 4768,1563 4838,1561 4909,1558 4979,1556 5050,1554 5120,1550 5191,1548 5261,1545 5332,1541 5402,1538 5473,1534 5543,1531 5614,1526 5634,1530 5698,1522\' rendition=\'Line\' xml:id=\'facs_5_line_1583250380065_295\'/>\n' +
        '\t\t\t\t<zone points=\'5756,1536 5658,1527 5583,1529 5508,1531 5432,1533 5357,1536 5282,1539 5206,1543 5131,1545 5056,1549 4981,1553 4905,1556 4830,1561 4755,1564 4679,1569 4604,1572 4529,1576 4453,1581 4378,1583 4303,1588 4228,1591 4228,1654 4303,1651 4378,1646 4453,1644 4529,1639 4604,1635 4679,1632 4755,1627 4830,1624 4905,1619 4981,1616 5056,1612 5131,1608 5206,1606 5282,1602 5357,1599 5432,1596 5508,1594 5583,1592 5658,1590 5756,1599\' rendition=\'Line\' xml:id=\'facs_5_r3l23\'/>\n' +
        '\t\t\t\t<zone points=\'5760,1591 5651,1595 5577,1591 5503,1591 5428,1591 5354,1591 5280,1594 5205,1596 5131,1599 5057,1603 4983,1607 4908,1612 4834,1617 4760,1623 4685,1628 4611,1633 4537,1637 4462,1643 4388,1647 4314,1651 4240,1655 4240,1721 4314,1717 4388,1713 4462,1709 4537,1703 4611,1699 4685,1694 4760,1689 4834,1683 4908,1678 4983,1673 5057,1669 5131,1665 5205,1662 5280,1660 5354,1657 5428,1657 5503,1657 5577,1657 5651,1661 5760,1657\' rendition=\'Line\' xml:id=\'facs_5_r3l24\'/>\n' +
        '\t\t\t\t<zone points=\'5714,1650 5643,1651 5504,1654 5433,1656 5362,1658 5292,1660 5221,1663 5150,1666 5079,1670 5008,1673 4938,1678 4867,1681 4796,1685 4725,1690 4654,1694 4584,1698 4513,1703 4442,1708 4371,1712 4300,1717 4230,1722 4230,1781 4300,1776 4371,1771 4442,1767 4513,1762 4584,1757 4654,1753 4725,1749 4796,1744 4867,1740 4938,1737 5008,1732 5079,1729 5150,1725 5221,1722 5292,1719 5362,1717 5433,1715 5504,1713 5643,1710 5714,1709\' rendition=\'Line\' xml:id=\'facs_5_r3l25\'/>\n' +
        '\t\t\t\t<zone points=\'5692,1712 5591,1717 5520,1719 5449,1720 5378,1723 5307,1726 5236,1730 5165,1733 5094,1738 5023,1742 4952,1745 4881,1750 4810,1754 4739,1757 4668,1762 4597,1766 4526,1770 4455,1773 4384,1776 4313,1780 4242,1782 4242,1836 4313,1834 4384,1830 4455,1827 4526,1824 4597,1820 4668,1816 4739,1811 4810,1808 4881,1804 4952,1799 5023,1796 5094,1792 5165,1787 5236,1784 5307,1780 5378,1777 5449,1774 5520,1773 5591,1771 5692,1766\' rendition=\'Line\' xml:id=\'facs_5_r3l26\'/>\n' +
        '\t\t\t\t<zone points=\'5718,1755 5690,1763 5662,1761 5590,1764 5519,1767 5448,1771 5377,1776 5306,1781 5235,1785 5164,1791 5093,1796 5022,1801 4951,1806 4879,1810 4808,1815 4737,1819 4666,1823 4595,1828 4524,1831 4453,1834 4382,1835 4311,1837 4240,1837 4240,1902 4311,1902 4382,1900 4453,1899 4524,1896 4595,1893 4666,1888 4737,1884 4808,1880 4879,1875 4951,1871 5022,1866 5093,1861 5164,1856 5235,1850 5306,1846 5377,1841 5448,1836 5519,1832 5590,1829 5662,1826 5690,1828 5718,1820\' rendition=\'Line\' xml:id=\'facs_5_line_1583250404571_307\'/>\n' +
        '\t\t\t\t<zone points=\'5751,1828 5651,1821 5577,1821 5503,1823 5429,1825 5355,1829 5280,1832 5206,1838 5132,1843 5058,1849 4984,1856 4909,1861 4835,1868 4761,1874 4687,1881 4613,1885 4538,1890 4464,1895 4390,1899 4316,1901 4242,1903 4242,1975 4316,1973 4390,1971 4464,1967 4538,1962 4613,1957 4687,1953 4761,1946 4835,1940 4909,1933 4984,1928 5058,1921 5132,1915 5206,1910 5280,1904 5355,1901 5429,1897 5503,1895 5577,1893 5651,1893 5751,1900\' rendition=\'Line\' xml:id=\'facs_5_r3l29\'/>\n' +
        '\t\t\t\t<zone points=\'5718,1890 5645,1890 5572,1891 5499,1892 5426,1896 5353,1899 5280,1903 5207,1910 5134,1914 5061,1920 4988,1927 4915,1933 4842,1939 4769,1946 4696,1951 4623,1957 4550,1962 4477,1966 4404,1971 4331,1974 4258,1976 4258,2041 4331,2039 4404,2036 4477,2031 4550,2027 4623,2022 4696,2016 4769,2011 4842,2004 4915,1998 4988,1992 5061,1985 5134,1979 5207,1975 5280,1968 5353,1964 5426,1961 5499,1957 5572,1956 5645,1955 5718,1955\' rendition=\'Line\' xml:id=\'facs_5_r3l30\'/>\n' +
        '\t\t\t\t<zone points=\'4248,2097 4273,2082 4388,2042 4446,2036 4505,2030 4563,2025 5499,1950 5696,1943 5696,1997 4248,2129\' rendition=\'Line\' xml:id=\'facs_5_line_1590389143462_259\'/>\n' +
        '\t\t\t\t<zone points=\'5657,2052 5603,2019 5523,2015 5417,2033 5348,2040 5278,2045 5209,2053 5139,2058 5070,2064 5000,2071 4931,2076 4861,2082 4792,2089 4722,2094 4653,2100 4583,2105 4514,2110 4444,2115 4375,2120 4305,2126 4236,2130 4236,2176 4305,2172 4375,2166 4444,2161 4514,2156 4583,2151 4653,2146 4722,2140 4792,2135 4861,2128 4931,2122 5000,2117 5070,2110 5139,2104 5209,2099 5278,2091 5348,2086 5417,2079 5523,2061 5603,2065 5657,2098\' rendition=\'Line\' xml:id=\'facs_5_r3l33\'/>\n' +
        '\t\t\t\t<zone points=\'5739,2063 5596,2081 5526,2083 5456,2087 5386,2091 5316,2095 5246,2099 5176,2106 5106,2111 5036,2117 4967,2124 4897,2130 4827,2136 4757,2143 4687,2149 4617,2155 4547,2160 4477,2166 4407,2169 4337,2174 4268,2177 4268,2232 4337,2229 4407,2224 4477,2221 4547,2215 4617,2210 4687,2204 4757,2198 4827,2191 4897,2185 4967,2179 5036,2172 5106,2166 5176,2161 5246,2154 5316,2150 5386,2146 5456,2142 5526,2138 5596,2136 5739,2118\' rendition=\'Line\' xml:id=\'facs_5_r3l34\'/>\n' +
        '\t\t\t\t<zone points=\'5724,2147 5652,2143 5580,2143 5508,2143 5436,2145 5364,2149 5292,2152 5220,2157 5148,2163 5076,2169 5004,2175 4932,2182 4860,2189 4788,2196 4716,2204 4644,2210 4572,2216 4500,2222 4428,2227 4356,2231 4284,2233 4284,2293 4356,2291 4428,2287 4500,2282 4572,2276 4644,2270 4716,2264 4788,2256 4860,2249 4932,2242 5004,2235 5076,2229 5148,2223 5220,2217 5292,2212 5364,2209 5436,2205 5508,2203 5580,2203 5652,2203 5724,2207\' rendition=\'Line\' xml:id=\'facs_5_r3l35\'/>\n' +
        '\t\t\t\t<zone points=\'5745,2232 5650,2224 5579,2222 5508,2220 5437,2220 5366,2222 5295,2224 5224,2227 5153,2232 5082,2236 5011,2242 4939,2247 4868,2254 4797,2259 4726,2265 4655,2271 4584,2277 4513,2282 4442,2286 4371,2290 4300,2294 4300,2352 4371,2348 4442,2344 4513,2340 4584,2335 4655,2329 4726,2323 4797,2317 4868,2312 4939,2305 5011,2300 5082,2294 5153,2290 5224,2285 5295,2282 5366,2280 5437,2278 5508,2278 5579,2280 5650,2282 5745,2290\' rendition=\'Line\' xml:id=\'facs_5_r3l36\'/>\n' +
        '\t\t\t\t<zone points=\'5760,2297 5688,2293 5616,2288 5544,2287 5472,2285 5401,2287 5329,2289 5257,2292 5185,2297 5113,2303 5042,2308 4970,2312 4898,2320 4826,2325 4754,2331 4683,2336 4611,2342 4539,2347 4467,2349 4395,2352 4324,2353 4324,2413 4395,2412 4467,2409 4539,2407 4611,2402 4683,2396 4754,2391 4826,2385 4898,2380 4970,2372 5042,2368 5113,2363 5185,2357 5257,2352 5329,2349 5401,2347 5472,2345 5544,2347 5616,2348 5688,2353 5760,2357\' rendition=\'Line\' xml:id=\'facs_5_r3l37\'/>\n' +
        '\t\t\t\t<zone points=\'5698,2362 5629,2358 5561,2358 5493,2358 5425,2358 5357,2360 5289,2364 5221,2368 5153,2372 5085,2377 5017,2381 4948,2387 4880,2392 4812,2397 4744,2401 4676,2406 4608,2409 4540,2412 4472,2414 4404,2414 4336,2414 4336,2475 4404,2475 4472,2475 4540,2473 4608,2470 4676,2467 4744,2462 4812,2458 4880,2453 4948,2448 5017,2442 5085,2438 5153,2433 5221,2429 5289,2425 5357,2421 5425,2419 5493,2419 5561,2419 5629,2419 5698,2423\' rendition=\'Line\' xml:id=\'facs_5_r3l38\'/>\n' +
        '\t\t\t\t<zone points=\'5730,2432 5660,2426 5591,2422 5521,2420 5452,2420 5382,2420 5313,2422 5243,2426 5174,2430 5104,2434 5035,2438 4965,2444 4896,2450 4826,2456 4757,2461 4687,2465 4618,2469 4548,2472 4479,2474 4409,2476 4340,2474 4340,2545 4409,2547 4479,2545 4548,2543 4618,2540 4687,2536 4757,2532 4826,2527 4896,2521 4965,2515 5035,2509 5104,2505 5174,2501 5243,2497 5313,2493 5382,2491 5452,2491 5521,2491 5591,2493 5660,2497 5730,2503\' rendition=\'Line\' xml:id=\'facs_5_r3l39\'/>\n' +
        '\t\t\t\t<zone points=\'5640,2471 5543,2472 5480,2475 5417,2477 5354,2482 5291,2485 5228,2489 5165,2495 5102,2501 5039,2507 4977,2511 4914,2518 4851,2523 4788,2527 4725,2532 4662,2537 4599,2540 4536,2543 4473,2545 4410,2547 4348,2547 4348,2618 4410,2618 4473,2616 4536,2614 4599,2611 4662,2608 4725,2603 4788,2598 4851,2594 4914,2589 4977,2582 5039,2578 5102,2572 5165,2566 5228,2560 5291,2556 5354,2553 5417,2548 5480,2546 5543,2543 5640,2542\' rendition=\'Line\' xml:id=\'facs_5_r3l40\'/>\n' +
        '\t\t\t\t<zone points=\'5714,2545 5645,2543 5577,2541 5509,2541 5440,2543 5372,2545 5304,2549 5235,2553 5167,2557 5099,2562 5031,2568 4962,2575 4894,2580 4826,2587 4757,2594 4689,2598 4621,2604 4552,2609 4484,2614 4416,2617 4348,2619 4348,2700 4416,2698 4484,2695 4552,2690 4621,2685 4689,2679 4757,2675 4826,2668 4894,2661 4962,2656 5031,2649 5099,2643 5167,2638 5235,2634 5304,2630 5372,2626 5440,2624 5509,2622 5577,2622 5645,2624 5714,2626\' rendition=\'Line\' xml:id=\'facs_5_r3l41\'/>\n' +
        '\t\t\t\t<zone points=\'5736,2614 5647,2611 5578,2611 5510,2613 5441,2616 5373,2619 5304,2622 5235,2627 5167,2633 5098,2638 5030,2645 4961,2650 4892,2657 4824,2663 4755,2669 4687,2675 4618,2681 4549,2688 4481,2692 4412,2696 4344,2701 4344,2798 4412,2793 4481,2789 4549,2785 4618,2778 4687,2772 4755,2766 4824,2760 4892,2754 4961,2747 5030,2742 5098,2735 5167,2730 5235,2724 5304,2719 5373,2716 5441,2713 5510,2710 5578,2708 5647,2708 5736,2711\' rendition=\'Line\' xml:id=\'facs_5_r3l42\'/>\n' +
        '\t\t\t\t<zone points=\'5638,2683 5574,2687 5510,2691 5446,2697 5382,2702 5318,2706 5254,2711 5190,2715 5126,2719 5062,2725 4999,2729 4935,2734 4871,2740 4807,2747 4743,2753 4679,2759 4615,2766 4551,2773 4487,2782 4423,2789 4360,2799 4360,2889 4423,2879 4487,2872 4551,2863 4615,2856 4679,2849 4743,2843 4807,2837 4871,2830 4935,2824 4999,2819 5062,2815 5126,2809 5190,2805 5254,2801 5318,2796 5382,2792 5446,2787 5510,2781 5574,2777 5638,2773\' rendition=\'Line\' xml:id=\'facs_5_r3l43\'/>\n' +
        '\t\t\t\t<zone points=\'5681,2784 5586,2785 5524,2788 5462,2793 5400,2797 5338,2804 5276,2810 5214,2816 5152,2822 5090,2828 5028,2835 4966,2840 4904,2848 4842,2854 4780,2859 4718,2865 4656,2872 4594,2877 4532,2882 4470,2886 4408,2890 4408,2957 4470,2953 4532,2949 4594,2944 4656,2939 4718,2932 4780,2926 4842,2921 4904,2915 4966,2907 5028,2902 5090,2895 5152,2889 5214,2883 5276,2877 5338,2871 5400,2864 5462,2860 5524,2855 5586,2852 5681,2851\' rendition=\'Line\' xml:id=\'facs_5_r3l44\'/>\n' +
        '\t\t\t\t<zone points=\'5716,2870 5651,2870 5586,2870 5522,2870 5457,2873 5392,2876 5328,2880 5263,2885 5199,2890 5134,2897 5070,2902 5005,2910 4940,2916 4876,2922 4811,2929 4747,2935 4682,2941 4617,2946 4553,2951 4488,2956 4424,2958 4424,3033 4488,3031 4553,3026 4617,3021 4682,3016 4747,3010 4811,3004 4876,2997 4940,2991 5005,2985 5070,2977 5134,2972 5199,2965 5263,2960 5328,2955 5392,2951 5457,2948 5522,2945 5586,2945 5651,2945 5716,2945\' rendition=\'Line\' xml:id=\'facs_5_r3l45\'/>\n' +
        '\t\t\t\t<zone points=\'5272,2956 5229,2960 5186,2963 5144,2966 5101,2970 5059,2974 5016,2978 4973,2982 4931,2986 4888,2990 4846,2995 4803,2999 4760,3003 4718,3008 4675,3011 4633,3016 4590,3020 4547,3023 4505,3027 4462,3030 4420,3034 4420,3100 4462,3096 4505,3093 4547,3089 4590,3086 4633,3082 4675,3077 4718,3074 4760,3069 4803,3065 4846,3061 4888,3056 4931,3052 4973,3048 5016,3044 5059,3040 5101,3036 5144,3032 5186,3029 5229,3026 5272,3022\' rendition=\'Line\' xml:id=\'facs_5_r3l46\'/>\n' +
        '\t\t\t\t<zone points=\'5755,3069 5678,3066 5630,3064 5583,3063 5535,3063 5488,3063 5440,3065 5392,3067 5345,3069 5297,3073 5250,3077 5202,3080 5154,3085 5107,3089 5059,3091 5012,3094 4964,3097 4916,3099 4869,3101 4821,3101 4774,3101 4774,3143 4821,3143 4869,3143 4916,3141 4964,3139 5012,3136 5059,3133 5107,3131 5154,3127 5202,3122 5250,3119 5297,3115 5345,3111 5392,3109 5440,3107 5488,3105 5535,3105 5583,3105 5630,3106 5678,3108 5755,3111\' rendition=\'Line\' xml:id=\'facs_5_r3l47\'/>\n' +
        '\t\t\t\t<zone points=\'5779,3087 5665,3076 5588,3078 5512,3082 5435,3085 5359,3088 5282,3093 5205,3098 5129,3102 5052,3107 4976,3113 4899,3116 4822,3121 4746,3126 4669,3130 4593,3134 4516,3137 4439,3140 4363,3142 4286,3144 4210,3144 4210,3227 4286,3227 4363,3225 4439,3223 4516,3220 4593,3217 4669,3213 4746,3209 4822,3204 4899,3199 4976,3196 5052,3190 5129,3185 5205,3181 5282,3176 5359,3171 5435,3168 5512,3165 5588,3161 5665,3159 5779,3170\' rendition=\'Line\' xml:id=\'facs_5_r3l48\'/>\n' +
        '\t\t\t\t<zone points=\'5680,3174 5589,3170 5516,3170 5443,3172 5370,3174 5297,3178 5224,3181 5151,3186 5078,3191 5005,3195 4933,3200 4860,3204 4787,3209 4714,3214 4641,3218 4568,3222 4495,3224 4422,3226 4349,3228 4276,3228 4204,3228 4204,3293 4276,3293 4349,3293 4422,3291 4495,3289 4568,3287 4641,3283 4714,3279 4787,3274 4860,3269 4933,3265 5005,3260 5078,3256 5151,3251 5224,3246 5297,3243 5370,3239 5443,3237 5516,3235 5589,3235 5680,3239\' rendition=\'Line\' xml:id=\'facs_5_r3l49\'/>\n' +
        '\t\t\t\t<zone points=\'5702,3220 5613,3223 5540,3227 5467,3233 5394,3236 5321,3241 5248,3246 5175,3250 5102,3254 5029,3258 4957,3262 4884,3266 4811,3268 4738,3272 4665,3276 4592,3279 4519,3282 4446,3285 4373,3289 4300,3291 4228,3294 4228,3361 4300,3358 4373,3356 4446,3352 4519,3349 4592,3346 4665,3343 4738,3339 4811,3335 4884,3333 4957,3329 5029,3325 5102,3321 5175,3317 5248,3313 5321,3308 5394,3303 5467,3300 5540,3294 5613,3290 5702,3287\' rendition=\'Line\' xml:id=\'facs_5_r3l50\'/>\n' +
        '\t\t\t\t<zone points=\'5700,3302 5603,3301 5532,3306 5461,3310 5391,3314 5320,3319 5249,3322 5179,3326 5108,3331 5037,3335 4967,3338 4896,3341 4825,3346 4754,3348 4684,3352 4613,3354 4542,3357 4472,3358 4401,3360 4330,3362 4260,3362 4260,3421 4330,3421 4401,3419 4472,3417 4542,3416 4613,3413 4684,3411 4754,3407 4825,3405 4896,3400 4967,3397 5037,3394 5108,3390 5179,3385 5249,3381 5320,3378 5391,3373 5461,3369 5532,3365 5603,3360 5700,3361\' rendition=\'Line\' xml:id=\'facs_5_r3l51\'/>\n' +
        '\t\t\t\t<zone points=\'5721,3370 5666,3366 5641,3364 5615,3364 5590,3364 5565,3364 5539,3366 5514,3366 5489,3367 5463,3368 5438,3370 5412,3370 5387,3371 5362,3372 5336,3372 5311,3372 5286,3370 5240,3376 5190,3378 5141,3380 5092,3383 5043,3387 4994,3390 4944,3393 4895,3398 4846,3400 4797,3404 4748,3406 4698,3410 4649,3413 4600,3416 4551,3418 4502,3420 4452,3422 4403,3422 4354,3422 4305,3422 4256,3422 4256,3485 4305,3485 4354,3485 4403,3485 4452,3485 4502,3483 4551,3481 4600,3479 4649,3476 4698,3473 4748,3469 4797,3467 4846,3463 4895,3461 4944,3456 4994,3453 5043,3450 5092,3446 5141,3443 5190,3441 5240,3439 5286,3433 5311,3435 5336,3435 5362,3435 5387,3434 5412,3433 5438,3433 5463,3431 5489,3430 5514,3429 5539,3429 5565,3427 5590,3427 5615,3427 5641,3427 5666,3429 5721,3433\' rendition=\'Line\' xml:id=\'facs_5_line_1583250480351_331\'/>\n' +
        '\t\t\t\t<zone points=\'5669,3426 5557,3432 5489,3434 5420,3436 5352,3439 5283,3443 5215,3446 5146,3450 5078,3455 5009,3460 4941,3464 4872,3468 4804,3473 4735,3477 4667,3479 4598,3482 4530,3484 4461,3486 4393,3486 4324,3486 4256,3484 4256,3532 4324,3534 4393,3534 4461,3534 4530,3532 4598,3530 4667,3527 4735,3525 4804,3521 4872,3516 4941,3512 5009,3508 5078,3503 5146,3498 5215,3494 5283,3491 5352,3487 5420,3484 5489,3482 5557,3480 5669,3474\' rendition=\'Line\' xml:id=\'facs_5_r3l54\'/>\n' +
        '\t\t\t</zone>\n' +
        '\t\t</surface>\n' +
        '\t</facsimile>\n' +
        '\t<text>\n' +
        '\t\t<body>\n' +
        '\t\t\t<pb facs=\'#facs_5\' n=\'5\'/>\n' +
        '\t\t\t<p facs=\'#facs_5_region_1612990312361_1667\'>\n' +
        '\t\t\t\t<lg>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1612990321802_1670\'>7)</l>\n' +
        '\t\t\t\t</lg>\n' +
        '\t\t\t</p>\n' +
        '\t\t\t<p facs=\'#facs_5_TextRegion_1612990185252_1656\'>\n' +
        '\t\t\t\t<lg>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1612990185387_1659\'>in divers lands</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l1\'>had had ado each with other in</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l2\'>the house of miesicord where this learning</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l3\'>knight lay by cause the traveller Leopold</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l4\'>came there to be healed for he was</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l5\'>sore wounded in his breast by a</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l6\'>spear wherewith a horrible and</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l7\'>dreadful dragon was smitten him.</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l8\'>(And) for which he did do make a</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l9\'>salve of volatile salt and oil as</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l10\'>much as he might suffice. And</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250546123_349\'>he said now he should go into</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250344537_282\'>the castle for to make merry with</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l15\'>them. And the traveller Leopold</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l16\'>said he should go other whither</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l17\'>for he was a man of cautels and</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l18\'>a subtile. Also the lady was of his</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l19\'>avis and repreved the learning</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l20\'>knight though she trowed well</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l21\'>that the traveller had said thing</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l22\'>that was false for his subtility.</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l23\'>But the learningknight would</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l24\'>not hear say nay nor do her</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l25\'>mandement ne have him in</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l26\'>aught contrarious to his list</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l27\'>and he said how it was a</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l28\'>marvellous castle. And the traveller</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l29\'>Leopold went into the castle to</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l30\'>rest for a space being sore of limb</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l31\'>after many marches F environing</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l33\'>and sometime venery.</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l34\'>And in the castle was</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l35\'>set a board that was of the birchwood</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583409386531_4137\'>of Finlandy and it was upheld</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l38\'>by four dwarfmen of that country</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l39\'>but they durst not move more</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583409384070_4133\'>for enchantment. And on this</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l42\'>board were frightful swords and</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l43\'>knives that are made in a</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l44\'>great cavern by swinking demons</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l45\'>out of white flames that they</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l46\'>fix then in the horns of buffalos</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l47\'>and stags that there abound</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l48\'>marvellously. And there were vessels</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l49\'>that are wrought by magic out</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r1l50\'>of seasand and the air by a</l>\n' +
        '\t\t\t\t</lg>\n' +
        '\t\t\t</p>\n' +
        '\t\t\t<p facs=\'#facs_5_TextRegion_1612990185252_1655\'>\n' +
        '\t\t\t\t<lg>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1612990185387_1658\'>in divers lands</l>\n' +
        '\t\t\t\t</lg>\n' +
        '\t\t\t</p>\n' +
        '\t\t\t<p facs=\'#facs_5_r2\'>\n' +
        '\t\t\t\t<lg>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250746255_407\'>F</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r2l1\'>And full fair cheer</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r2l2\'>and rich was on the</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r2l3\'>board that no</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r2l4\'>wight could devise</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r2l5\'>a fuller ne richer</l>\n' +
        '\t\t\t\t</lg>\n' +
        '\t\t\t</p>\n' +
        '\t\t\t<p facs=\'#facs_5_region_1612990419591_1682\'>\n' +
        '\t\t\t\t<lg>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1612990448752_1695\'>8)</l>\n' +
        '\t\t\t\t</lg>\n' +
        '\t\t\t</p>\n' +
        '\t\t\t<p facs=\'#facs_5_r3\'>\n' +
        '\t\t\t\t<lg>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l1\'>warlock with his breath that he</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l2\'>blases into them like bubbleware.</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1590388971545_219\'>F</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l3\'>And there was a vat of silver that</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l4\'>was moved by craft to open in which</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l5\'>lay strange fishes withouten heads</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l6\'>though misbelieving men nie that</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l7\'>this be possible things without</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l8\'>they see it yet natheless they are</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l9\'>so. And they lie in an oily water</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l10\'>brought from Portugal land because</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l11\'>of the fatness that therein is which</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l12\'>is like the liquor of the olivepress.</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l13\'>And also it was a marvel to see</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250363401_287\'>in that castle how by magic</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l16\'>they make a compost out of</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l17\'>fecund wheatkidneys of Chaldee</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l18\'>which, by aid of certain angry</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250491208_338\'>juices that they do in to it swells</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250380065_295\'>up wondrously like a vast</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l23\'>mountain. And they teach the</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l24\'>serpents there to entwine them-</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l25\'>selves up on long sticks out</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l26\'>of the ground and of the scales</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250404571_307\'>of these serpents they brew out</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l29\'>a brewage like to mead. And</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l30\'>the learningknight let pour</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1590389143462_259\'>for the traveller a draught and</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l33\'>halp thereto the while all they</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l34\'>that were there drank every</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l35\'>each. And the traveller Leopold</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l36\'>did up his vizor for to pleasure</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l37\'>him and took apertly somewhat</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l38\'>in amity for he never drank</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l39\'>no manner of mead and anon</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l40\'>full privily he voided the</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l41\'>more part in his neighbour</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l42\'>glass and his neighbour nist</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l43\'>not of this wile . And he</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l44\'>sat down in the castle with</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l45\'>them to rest awhile. Thanked</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l46\'>be Almighty God.</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l47\'>This meanwhile this good</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l48\'>sister stood by the door and begged them</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l49\'>at the reverence of Jesu our alther</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l50\'>liege Lord to leave their wassailing</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l51\'>for there was above one quick with</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_line_1583250480351_331\'>child, a gentle dame, whose time</l>\n' +
        '\t\t\t\t\t<l facs=\'#facs_5_r3l54\'>hied fast. Sir Leopold heard</l>\n' +
        '\t\t\t\t</lg>\n' +
        '\t\t\t</p>\n' +
        '\t\t</body>\n' +
        '\t</text>\n' +
        '</TEI>\n';
    const parser = new DOMParser();
    const doc = parser.parseFromString(xmlStr, "application/xml");
    let zones = doc.getElementsByTagName('zone')
    const errorNode = doc.querySelector("parsererror");
    if (errorNode) {
        console.log("error while parsing");
    } else {
        return convertZonesToJson(zones);
    }
}

function convertZonesToJson(zones) {
    let annotations = []
    for (let i = 0; i < zones.length; i++) {
        let zone = zones[i];
        let annotation = {'target': {'selector': [{'type': 'SvgSelector'}]}}
        annotation.target.selector[0].value = "<svg><polygon points='" + zone.getAttribute('points') + "'></polygon></svg>";
        annotation.id = zone.getAttribute("xml:id");
        annotations.push(annotation);
    }
    return annotations;
}
